<template>
  <!-- <svg
    width="50"
    height="50"
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 599.56 586.4"
  > -->
  <svg
    width="50"
    height="50"
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 599.56 586.4"
  >
    <ellipse
      cx="299.78"
      cy="293.2"
      rx="290.78"
      ry="284.2"
      style="
        fill: #ebebeb;
        stroke: #cbcbcb;
        stroke-miterlimit: 10;
        stroke-width: 18px;
      "
    />
    <path
      d="m486.77,453.06c-.64,19.41-10.37,37.97-31.72,51.25-27.98,17.4-64.72,14.09-88.94-7.5-29.42-26.23-28.14-70.53,2.76-95.48,29.07-23.48,72.62-21.53,98.72,4.46,12.84,12.78,19.1,27.65,19.18,47.27Z"
    />
    <path
      d="m417.81,365.43h-236.87c.28-5.6.17-11.11.89-16.51,5.92-44.35,29.38-75.96,69.57-95.38,2.35-1.14,3.61-.7,5.35,1.14,9.63,10.17,21.81,15.14,35.55,16.34,17.42,1.52,33.51-1.81,47.05-13.74.44-.38.86-.77,1.28-1.18,3.99-3.83,4.05-3.94,9.1-1.39,38.24,19.33,60.76,50.1,66.95,92.58.86,5.88.76,11.9,1.12,18.14Z"
    />
    <path
      d="m359.54,201.53c-.55,19.55-8.87,38.25-27.15,51.63-23.95,17.53-55.4,14.19-76.13-7.56-25.18-26.42-24.09-71.04,2.36-96.17,24.89-23.65,62.16-21.69,84.5,4.49,10.99,12.88,16.35,27.85,16.42,47.61Z"
    />
    <path
      d="m470.63,457.99c-12.58,0-24.8,0-37.02,0q-4.47,0-4.47,4.61c0,12.96-.07,25.92.06,38.88.03,2.63-.76,3.4-3.36,3.33-6.04-.17-12.08-.06-18.5-.06,0-1.88,0-3.41,0-4.95,0-12.96-.06-25.92.05-38.88.02-2.39-.76-3-3.05-2.98-11.7.09-23.41.03-35.12.02-1.24,0-2.48,0-4.22,0,0-6.5-.07-12.74.13-18.97.02-.48,1.87-1.29,2.87-1.29,11.6-.09,23.2-.05,34.8-.05q4.18,0,4.19-4.28c0-11.5.08-22.99-.05-34.49-.03-2.65.82-3.38,3.38-3.3,6.02.17,12.05.05,18.47.05,0,1.85,0,3.29,0,4.72,0,11.39.08,22.79-.06,34.18-.03,2.53.78,3.19,3.2,3.16,11.6-.11,23.2-.06,34.8-.06,1.23,0,2.46,0,3.88,0v20.34Z"
      style="fill: #fff"
    />
  </svg>
</template>
