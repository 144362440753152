// COMPOSABLE
// IMPORT LIBRERIE VARIE

import { ref, onMounted, onUnmounted } from "vue";

export function useWindowWidth() {
  // DICHIARAZIONE VARIABILI
  const windowWidth = ref(window.innerWidth);

  onMounted(() => {
    window.addEventListener("resize", myEventHandler);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", myEventHandler);
  });
  const myEventHandler = (e) => {
    windowWidth.value = e.target.innerWidth;
  };
  // CONTROLLA SE L'INPUT E' DINAMICO O NO
  // SE SI CREA UN WATCHER PER L'INPUT
  // ALTRIMENTI ESEGUE LA FUNZIONE SOLO UNA VOLTA
  //   if (isRef(guid)) {
  
  //     watchEffect(doAvatar());
  //   } else {
  
  //     doAvatar();
  //   }
  // RETURN DELLE VARIABILI DA UTILIZZARE NEL COMPONENTE
  return { windowWidth };
}
