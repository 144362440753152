<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_session">
    <div class="modal-dialog">
      <!-- <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading> -->
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="id_session ? session : formValues"
        :validation-schema="schema"
        v-slot="{ values }"
        autocomplete="off"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ id_session ? "Modifica sessione" : "Inserimento sessione" }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_session_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <template v-if="(id_session && loaded_session) || !id_session">
            <div class="modal-body">
              <div class="card-body p-6">
                <div class="ps-5 pe-5 repeater-form">
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700 pe-3">Nome</label>
                      <Field name="name" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="name" />
                    </div>
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Data/ora
                      </label>

                      <VueDatePicker
                        placeholder="Selezionare data e ora "
                        :format="formatDateITA"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="true"
                        inputClassName="h-28"
                        autoApply
                        v-model="date_var"
                        :is-24="true"
                        :start-time="startTime"
                        minutes-grid-increment="10"
                        @update:modelValue="
                          date_var = moment($event).format('YYYY-MM-DD HH:mm')
                        "
                      />
                      <Field
                        name="date"
                        type="hidden"
                        v-model="date_var"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="date" />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-700 pe-3">Attivo</label>
                      <br />
                      <Field
                        type="checkbox"
                        name="active"
                        id="active"
                        :value="true"
                        style="margin-top: 2%"
                      />
                      <ErrorMessage v-show="view_errors" name="active" />
                    </div>
                    <!-- <div class="col-md-2">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Overbooking</label
                      >
                      <br />
                      <Field
                        type="checkbox"
                        name="overbooking"
                        id="overbooking"
                        :value="true"
                      />
                      <ErrorMessage v-show="view_errors" name="overbooking" />
                    </div> -->
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-700 pe-3">Eventi </label>
                      <Field
                        class="form-select"
                        name="id_event"
                        as="select"
                        @change="get_session_type(values)"
                        disabled
                        :value="id_event"
                      >
                        <option
                          v-for="event in lookupEvents"
                          :key="event.id"
                          :value="event.id"
                        >
                          {{ event.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_event" />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-700 pe-3">Anni </label>
                      <Field
                        class="form-select"
                        name="id_year"
                        as="select"
                        @change="get_session_type(values)"
                        disabled
                        :value="id_year"
                      >
                        <option
                          v-for="year in lookupYears"
                          :key="year.id"
                          :value="year.id"
                          :selected="year.id == id_year"
                        >
                          {{ year.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_year" />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Tipo di sessione
                      </label>
                      <Field
                        class="form-select"
                        name="id_cross_year_event_session_type"
                        as="select"
                      >
                        <option
                          v-for="sessione in lookupSessionType"
                          :key="sessione.id"
                          :value="sessione.id"
                        >
                          {{ sessione.label }}
                        </option>
                      </Field>

                      <ErrorMessage
                        v-show="view_errors"
                        name="id_cross_year_event_session_type"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-12">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Descrizione</label
                      >
                      <Field
                        as="textarea"
                        name="description"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="description" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-6"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="kt_modal_add_session_close_button"
              >
                Chiudi
              </button>
              <button
                type="submit"
                class="badge bg-light-success text-success rounded blueFit fs-6"
              >
                Salva
              </button>
            </div>
          </template>
          <Loader v-else />
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from "moment";
import { formatDateITA, startTime } from "@/functions/formatDate";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";

export default {
  name: "add-session-modal",
  components: {
    VueDatePicker,
    Field,
    ErrorMessage,
    Form,
  },
  props: {
    id_session: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;
      
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      values.id_session = props.id_session;
      values.active = values.active ? values.active : false;
      // values.overbooking = values.overbooking ? values.overbooking : false;
      await callFunctionAfterAlert(
        props.id_session ? API.SESSIONS_EDIT : API.SESSIONS_ADD,
        values,
        `Sei sicuro di voler ${
          props.id_session ? "modificare" : "creare"
        } la sessione <b>${values.name}</b>?`,
        "Sessione creata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedsession) => {
        if (!props.id_session) {
          resetForm();
          date_var.value = null;
        }
        if (isAddedorModifiedsession) {
          document.getElementById("kt_modal_add_session_close_button").click();
          emit("getList");
        }
      });
    };
    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      date: yup.string().required("Obbligatorio"),
      description: yup.string().required("Obbligatorio"),
      active: yup.boolean(),
      overbooking: yup.boolean(),
      id_cross_year_event_session_type: yup.number().required("Obbligatorio"),
      id_year: yup.number().required("Obbligatorio"),
      id_event: yup.number().required("Obbligatorio"),
    });

    const date_var = ref(null);

    const payload = ref({ id_session: props.id_session });

    const {
      element: sessionResults,
      loaded: loaded_session,
      getElement: get_session,
      status: status_session,
    } = useApiCall(API.SESSIONS_VIEW, payload);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ee|yy" }
    );

    get_lookups();

    watch(
      () => props.id_session,
      async (newVal) => {
        if (newVal) {
          payload.value.id_session = newVal;
          await get_session();

          date_var.value = sessionResults.value.results[0].date;

          /* await get_session_type({
            id_event: sessionResults.value
              ? sessionResults.value.results[0].id_event
              : "",
            id_year: sessionResults.value
              ? sessionResults.value.results[0].id_year
              : "",
          });*/
        } else {
          date_var.value = null;
          sessionResults.value = null;
        }
      }
    );

    const payloadSessionType = ref({});

    const { element: sessionTypeResults, getElement: get_sessionType } =
      useApiCall(API.LOOKUP_SESSION_TYPE, payloadSessionType);

    const get_session_type = async ({ id_event, id_year }) => {
      
      if (id_event && id_year) {
        payloadSessionType.value = { id_event, id_year };
        await get_sessionType();
        
      }
    };

    get_session_type({
      id_event: props.id_event,
      id_year: props.id_year,
    });

    const formValues = ref({
      id_event: props.id_event,
      id_year: props.id_year,
    });

    watch(
      () => props.id_event,
      () => {
        formValues.value = {
          id_event: props.id_event,
          id_year: props.id_year,
        };
        if (props.id_event && props.id_year) {
          get_session_type({
            id_event: props.id_event,
            id_year: props.id_year,
          });
        }
      }
    );
    watch(
      () => props.id_year,
      () => {
        formValues.value = {
          id_event: props.id_event,
          id_year: props.id_year,
        };
        if (props.id_event && props.id_year) {
          get_session_type({
            id_event: props.id_event,
            id_year: props.id_year,
          });
        }
      }
    );

    return {
      formValues,
      get_session_type,
      lookups,
      sessionResults,
      loaded_session,
      status_session,
      moment,
      formatDateITA,
      date_var,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      sessionTypeResults,
      view_errors,
      startTime,
    };
  },
  computed: {
    lookupYears() {
      return this.lookups && this.lookups.result && this.lookups.result[1]
        ? this.lookups.result[1].years
        : [];
    },
    lookupEvents() {
      return this.lookups && this.lookups.result && this.lookups.result[0]
        ? this.lookups.result[0].events
        : [];
    },
    lookupSessionType() {
      return this.sessionTypeResults ? this.sessionTypeResults.results : [];
    },
    session() {
      return this.id_session && this.sessionResults
        ? this.sessionResults.results[0]
        : {};
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
