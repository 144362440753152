<template>
  <div class="row justify-content-center border-top border-bottom ps-2 pe-2">
    <!-- v-slot="{ values }" -->
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <template v-if="!isEdit">
      <div class="ps-0 col-2 text-center">
        <div class="my-7 cursor-pointer">
          <!-- <img
                    src="/media/logo-fitp.png"
                    class="rounded-circle width-100 bg-black"
                    alt="..."
                    style="width: 5rem; height: 5rem"
                  /> -->
          <input
            type="file"
            class="d-none"
            name="avatar"
            accept=".jpg, .jpeg, .png"
            :id="`addAvatar${accompagnatore.id_user}`"
            @change="onInputChange"
          />

          <img
            :src="
              invito
                ? `${blob}${
                    accompagnatore.photo
                      ? accompagnatore.photo
                      : 'anagrafiche/default.svg'
                  }?t=${timestamp}`
                : accompagnatore.photo
                ? accompagnatore.photo
                : `${blob}anagrafiche/default.svg`
            "
            class="rounded-circle width-100 bg-black"
            alt="..."
            :style="
              invito
                ? invito.photo.includes('default')
                  ? ''
                  : 'border: 2px solid #cbcbcb'
                : 'border: 2px solid #cbcbcb'
            "
            style="width: 4rem; height: 4rem"
            @click="clickImportFile"
          />
        </div>
      </div>
      <div class="col-7 row">
        <div class="row ps-5 mt-auto">
          <p
            class="mb-0 w-auto accompagnatore-nominativo text-capitalize"
            style="align-items: center !important"
          >
            {{ accompagnatore.name }} {{ accompagnatore.surname }}
          </p>
        </div>

        <div class="row ps-5 accompagnatore-data">
          <p class="mb-0 w-auto accompagnatore-data text-capitalize">
            {{ moment(accompagnatore.date_of_birth).format("DD/MM/YYYY") }}
          </p>
        </div>
      </div>
      <div class="col-3 row justify-content-end">
        <div
          class="my-auto row contenitore-bottoni"
          v-if="
            (guid_person && guid_invitation && invito.status == 'inviato') ||
            (!guid_person && !guid_invitation) ||
            consigliere_puo_modificare
          "
        >
          <button
            class="btn btn-sm dropdown p-1 col-6"
            type="button"
            @click="isEdit = !isEdit"
          >
            <i class="bi bi-pen text-dark fs-2"></i>
          </button>
          <button
            class="btn btn-sm dropdown p-1 col-6"
            type="button"
            @click="$emit('Elimina', accompagnatore)"
          >
            <i class="bi bi-trash text-dark fs-2"></i>
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <Form
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="formValues"
        :validation-schema="schema"
        v-slot="{ values }"
        autocomplete="off"
      >
        <div class="row mt-4 justify-content-center mb-2">
          <div class="ps-0 col-2 text-center">
            <div class="my-7 cursor-pointer">
              <input
                type="file"
                class="d-none"
                name="avatar"
                accept=".jpg, .jpeg, .png"
                :id="`addAvatar${accompagnatore.id_user}2`"
                @change="onInputChange"
              />
              <img
                :src="`${blob}${accompagnatore.photo}?t=${timestamp}`"
                class="rounded-circle width-100 bg-black"
                alt="..."
                :style="
                  invito.photo.includes('default')
                    ? ''
                    : 'border: 2px solid #cbcbcb'
                "
                style="width: 5.5rem; height: 5.5rem"
              />
              <!-- <BlankProfile @click="clickImportFile" /> -->
            </div>
          </div>
          <template v-if="windowWidth <= 767">
            <div class="col-9 row align-items-center">
              <div class="row">
                <Field
                  name="surname"
                  class="form-control col-xl-3 mb-1"
                  placeholder="Cognome"
                />
                <ErrorMessage v-show="view_errors" name="surname" />
                <Field
                  name="name"
                  class="form-control col-xl-3 mb-1"
                  placeholder="Nome"
                />
                <ErrorMessage v-show="view_errors" name="name" />
              </div>
              <div class="row">
                <div class="p-0">
                  <VueDatePicker
                    placeholder="Data di nascita"
                    :format="format"
                    :max-date="new Date()"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28 w-100 mb-1"
                    autoApply
                    :model-value="
                      moment(values.date_of_birth).format('DD-MM-YYYY')
                    "
                    @update:modelValue="
                      values.date_of_birth = $event
                        ? moment($event).format('YYYY-MM-DD')
                        : null
                    "
                  />
                  <Field
                    name="date_of_birth"
                    type="hidden"
                    v-model="date_of_birth"
                    class="form-control"
                  />
                  <ErrorMessage v-show="view_errors" name="date" />
                </div>
              </div>
            </div>
            <div class="col-1 row">
              <div class="my-auto row">
                <button
                  class="btn btn-sm dropdown p-1 text-start"
                  type="button"
                  @click="isEdit = !isEdit"
                >
                  <i class="bi bi-pen text-dark fs-2"></i>
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-7 row align-items-center">
              <div class="row">
                <div class="col-12">
                  <Field
                    name="surname"
                    class="form-control col-xl-3 mb-1"
                    placeholder="Cognome"
                  />
                  <ErrorMessage v-show="view_errors" name="surname" />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <Field
                    name="name"
                    class="form-control col-xl-3 mb-1"
                    placeholder="Nome"
                  />
                  <ErrorMessage v-show="view_errors" name="name" />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <VueDatePicker
                    placeholder="Data di nascita"
                    format="dd/MM/yyyy"
                    :max-date="new Date()"
                    :monthChangeOnScroll="false"
                    :enableTimePicker="false"
                    inputClassName="h-28 w-100 mb-1"
                    autoApply
                    :model-value="values.date_of_birth"
                    @update:modelValue="
                      values.date_of_birth = $event
                        ? moment($event).format('YYYY-MM-DD')
                        : null
                    "
                  />
                  <Field
                    name="date_of_birth"
                    type="hidden"
                    v-model="date_of_birth"
                    class="form-control"
                  />
                  <ErrorMessage v-show="view_errors" name="date" />
                </div>
              </div>
            </div>
            <!-- <div class="col-1 row"></div> -->
            <div class="col-3 row align-items-center">
              <div class="col-3">
                <button
                  class="btn btn-sm dropdown p-1 text-start"
                  type="button"
                  :id="`changeEdit${accompagnatore.id_user}`"
                  @click="isEdit = !isEdit"
                >
                  <i class="bi bi-pen text-dark fs-2"></i>
                </button>
              </div>
              <button
                type="submit"
                class="col-9 badge bg-light-warning text-warning rounded fs-6"
              >
                Modifica
              </button>
            </div>
          </template>
        </div>
        <button
          v-if="windowWidth <= 767"
          type="submit"
          class="mt-4 badge bg-light-warning text-warning rounded fs-6 px-3 w-100 px-4 mb-2"
        >
          Modifica
        </button>
      </Form>
    </template>
  </div>
</template>
<script>
import BlankProfile from "./SVG/BlankProfile.vue";
import { ref, watch } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import { INVITI_STATUS } from "@/enums/costanti";
import { useWindowWidth } from "@/composables/getWindowWidth";
import useFileList from "@/composables/file-list";
import { alertFailed } from "@/functions/swAlert";
import { fileSender } from "@/db/dbManag";
import { API } from "@/enums/costanti";

export default {
  props: {
    accompagnatore: {
      type: Object,
    },
    invito: {
      type: Object,
    },
    guid_person: {
      type: Object,
    },
    guid_invitation: {
      type: Object,
    },
    from_add_person_to_session: {
      type: Boolean,
    },
    consigliere_puo_modificare: {
      type: Boolean,
    },
  },
  emits: ["Modifica", "Elimina", "Refresh", "AddFoto"],
  components: {
    // BlankProfile,
    VueDatePicker,
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const isEdit = ref(false);
    const formValues = ref({ ...props.accompagnatore });
    const onSubmit = (values) => {
      values.nominative = `${values.name} ${values.surname}`;
      emit("Modifica", values);
      formValues.value = { ...values };
    };
    const { windowWidth } = useWindowWidth();

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }
    watch(
      () => file.value.file,
      (value) => {
        if (file.value.file) {
          if (!props.from_add_person_to_session) {
            caricaFoto();
          } else {
            emit("AddFoto", {
              values: props.accompagnatore,
              fileToSend: file.value,
            });
          }
        } else {
          setTimeout(() => {
            file_controllato.value = false;
            errori_txt.value = "";
          }, 1000);
        }
      }
    );

    const file_controllato = ref(false);
    const errori_txt = ref("");
    const isLoading = ref(false);

    const caricaFoto = async () => {
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }

      file_controllato.value = false;
      errori_txt.value = "";
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append(
        "item_id",
        props.accompagnatore.id_user
          ? props.accompagnatore.id_user
          : props.accompagnatore.guid
      );
      bodyFormData.value.append("id_invitation", props.invito.id);
      bodyFormData.value.append("id_event", props.invito.id_event);
      bodyFormData.value.append("id_user", props.invito.id_user);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("Refresh");
          }
        }
      );

      // trova il modo per caricare una foto e mostrarla senza blob
    };

    const clickImportFile = () => {
      document
        .getElementById(`addAvatar${props.accompagnatore.id_user}`)
        .click();
    };
    return {
      clickImportFile,
      isLoading,
      onInputChange,
      isEdit,
      formValues,
      moment,
      onSubmit,
      INVITI_STATUS,
      windowWidth,
      blob: process.env.VUE_APP_BLOB,
      timestamp: moment().unix(),
      file,
    };
  },
};
</script>
<style scoped>
.accompagnatore-nominativo {
  font-size: 1.2rem;
  font-weight: 600;
}
.accompagnatore-data {
  font-size: 0.9rem;
  font-weight: 500;
  color: grey;
}
.contenitore-bottoni {
  max-width: 6rem;
}
</style>
