<template>
  <div>
    <!-- <FiltersSessions
      @getList="get_sessions_and_set_checked"
      @resetFilters="resetFilters"
      :loaded="loaded_sessions_and_checked"
      @setPayload="setPayload"
      :payload="payload"
      :pressedSearch="pressedSearch"
      @pressedSearch="pressedSearch = true"
    /> -->

    <TableSessions
      @getList="get_sessions_and_set_checked"
      @resetFilters="resetFilters"
      :objectList="sessions"
      :loaded="loaded_sessions_and_checked"
      :status="status_sessions"
      @setPayload="setPayload"
      :payload="payload"
      :pressedSearch="pressedSearch"
      :id_event="payload.id_event"
      :id_year="payload.id_year"
      :from_invitations="from_invitations"
      @checkedElement="$emit('checkedElement', $event)"
      @checkedElementSelezionato="$emit('checkedElementSelezionato', $event)"
      @checkedElementAccompagnatore="
        $emit('checkedElementAccompagnatore', $event)
      "
      :disable_semifinals_finals="disable_semifinals_finals"
      :is_ospite="is_ospite"
      :selected_sessions_length="selected_sessions_length"
      :from_invitations_consigliere="from_invitations_consigliere"
      :max_sessions_dirigente_ospite="max_sessions_dirigente_ospite"
      :accompagnatori="accompagnatori"
      :max_session_invitation_send_reached="max_session_invitation_send_reached"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
// import FiltersSessions from "../components/sessions/FiltersSessions_copy.vue";
import TableSessions from "../components/sessions/TableSessions_copy.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "sanzioni",
  components: {
    // FiltersSessions,
    TableSessions,
  },
  emits: [
    "listLength",
    "checkedElementSelezionato",
    "checkedElementAccompagnatore",
  ],
  props: {
    id_year_from_invitation: {
      type: Number,
    },
    id_event_from_invitation: {
      type: Number,
    },
    from_invitations: {
      type: Boolean,
    },
    checked_list_from_invitation: {
      type: Array,
    },
    disable_semifinals_finals: {
      type: Boolean,
    },
    is_ospite: {
      type: Boolean,
    },
    selected_sessions_length: {
      type: Number,
    },
    from_invitations_consigliere: {
      type: Boolean,
    },
    max_sessions_dirigente_ospite: {
      type: Number,
    },
    accompagnatori: {
      type: Array,
    },
    max_session_invitation_send_reached: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const guid_invitation = route.query.i;
    const guid_person = route.query.p;

    const pressedSearch = ref(false);
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);
    const payload = ref({
      // name: "string",
      // surname: "string",
      // date_of_birth: "",
      // tax_code: "",
      // phone_number: "string",
      // email: "string",
      // photo: "string",
      // id_language: 0,
      date: "",
      name: "",
      id_event: props.id_event_from_invitation
        ? props.id_event_from_invitation
        : id_event.value,
      id_year: props.id_year_from_invitation
        ? props.id_year_from_invitation
        : id_year.value,
      sortcolumn: "",
      sortorder: "",
      rowstoskip: 0,
      fetchrows:
        props.from_invitations || props.from_invitations_consigliere ? 100 : 20,
      id_user: guid_person,
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };

    const {
      element: sessions,
      loaded: loaded_sessions,
      getElement: get_sessions,
      status: status_sessions,
    } = useApiCall(API.SESSIONS_LIST, payload);

    onMounted(async () => {
      if (!props.from_invitations) {
        setCurrentPageBreadcrumbs("Sessioni", payload);
      }
      if (payload.value.id_year && payload.value.id_event)
        await get_sessions_and_set_checked();
    });
    watch([id_year, id_event], async () => {
      payload.value.id_year = id_year.value;
      payload.value.id_event = id_event.value;
      if (id_year.value && id_event.value) {
        await get_sessions_and_set_checked();
      }
    });

    watch(
      [
        () => props.id_year_from_invitation,
        () => props.id_event_from_invitation,
      ],
      async () => {
        if (props.id_year_from_invitation && props.id_event_from_invitation) {
          payload.value.id_year = props.id_year_from_invitation;
          payload.value.id_event = props.id_event_from_invitation;
          pressedSearch.value = true;
          await get_sessions_and_set_checked();
        }
      }
    );

    const resetFilters = () => {
      payload.value = {
        date: "",
        name: "",
        id_event: props.id_event_from_invitation
          ? props.id_event_from_invitation
          : id_event.value,
        id_year: props.id_year_from_invitation
          ? props.id_year_from_invitation
          : id_year.value,
        sortcolumn: "",
        sortorder: "",
        rowstoskip: 0,
        fetchrows: 25,
      };
      get_sessions_and_set_checked();
    };
    const checked_setted = ref(false);
    const get_sessions_and_set_checked = async () => {
      await get_sessions();
      emit("listLength", sessions.value.results.length);
      checked_setted.value = false;
      if (
        props.checked_list_from_invitation &&
        props.checked_list_from_invitation.length
      ) {
        sessions.value.results.forEach((session, idx) => {
          props.checked_list_from_invitation.forEach((checked_session) => {
            if (checked_session.id_session === session.id) {
              session.checked = true;
            }
          });
        });
      }

      checked_setted.value = true;
    };
    return {
      checked_setted,
      get_sessions_and_set_checked,
      resetFilters,
      setPayload,

      sessions,
      loaded_sessions,
      status_sessions,
      trimInput,
      payload,
      pressedSearch,
      id_year,
      id_event,
    };
  },
  computed: {
    loaded_sessions_and_checked() {
      return this.loaded_sessions && this.checked_setted;
    },
  },
});
</script>
