<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_print_person_from_session"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              selected_id_person_guest
                ? "Modifica Accompagnatore"
                : "Stampa Braccialetti"
            }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="kt_modal_print_person_from_session_closex"
            @click="freetext = ''"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <template v-if="loaded_session && id_session && seats">
          <Form
            class="form"
            @invalid-submit="onInvalidSubmit"
            @submit="onSubmit"
            :initial-values="selected_id_person_guest ? person : formValues"
            :validation-schema="schema"
            :v-slot="{ values }"
            autocomplete="off"
          >
            <div class="modal-body" v-if="!selected_id_person_guest">
              <div class="card-body p-6">
                <div class="ps-5 pe-5 repeater-form">
                  <div class="row pb-4">
                    <InputList
                      parameterToShow="nome"
                      @isSelectedElement="
                        add_person_session($event);
                        clickCancellaPersona();
                      "
                      :placeholder="`Inserire cognome e nome della persona da cercare`"
                      getEntireRecord
                      :emitEventOnCancel="false"
                      style="margin-bottom: 2rem"
                      id_bottone_chiudi="chiudiPersonaAddPersonaSessione"
                      :disable_list="true"
                      @elementList="persone_sessione = $event"
                      @inputElement="freetext = $event"
                      @cancelElement="freetext = ''"
                      noApi
                    />
                    <div
                      style="overflow-y: scroll"
                      :style="filtered_seats.length ? 'height: 46rem;' : ''"
                    >
                      <div v-for="(element, idx) in filtered_seats" :key="idx">
                        <!-- bg-secondary -->
                        <!-- v-if="isMaggiorenne(element.date_of_birth)" -->
                        <div
                          class="lista-item mt-3 d-flex justify-content-between"
                          :class="
                            element.isDragging || element.id_seat != -1
                              ? ''
                              : ''
                          "
                        >
                          <div class="d-flex flex-row align-items-center">
                            <div
                              style="width: 1.3rem"
                              v-if="
                                element.id_gruppo_invitato == 66 ||
                                element.id_gruppo_invitato == 67 ||
                                element.id_gruppo_invitato == 68 ||
                                element.id_gruppo_invitato == 69
                              "
                              class="cerchio-legenda circle-20 ms-2"
                              :class="
                                element.id_gruppo_invitato == 66
                                  ? 'colore66'
                                  : element.id_gruppo_invitato == 67 ||
                                    element.id_gruppo_invitato == 68 ||
                                    element.id_gruppo_invitato == 69
                                  ? 'colore67'
                                  : ''
                              "
                            ></div>

                            <div class="font-italic text-uppercase ps-2">
                              {{ element.surname }} {{ element.name }}
                              {{
                                moment(element.date_of_birth).format(
                                  "DD/MM/YYYY"
                                )
                              }}
                              ({{ element.ean14_code }})
                            </div>
                          </div>

                          <div class="btn btn-sm dropdown p-1">
                            <Popper arrow v-if="element.accreditation_number">
                              <button type="button">
                                <i class="bi bi-qr-code text-dark fs-4"></i>
                              </button>
                              <template #content>
                                <div>
                                  {{ element.accreditation_number }}
                                </div></template
                              >
                            </Popper>

                            <Popper
                              arrow
                              v-if="!isMaggiorenne(element.date_of_birth)"
                            >
                              <button type="button">
                                <i
                                  class="bi bi-exclamation-triangle-fill text-danger text-center"
                                ></i>
                              </button>
                              <template #content>
                                <div>Minorenne</div></template
                              >
                            </Popper>

                            <button
                              class="btn btn-sm p-1"
                              type="button"
                              @click="deletePerson(element)"
                            >
                              <i class="bi bi-trash text-dark fs-4"></i>
                            </button>
                            <Popper arrow class="m-0" style="border: 0">
                              <button type="button">
                                <i
                                  class="bi bi-info-circle-fill text-center"
                                ></i>
                              </button>
                              <template #content>
                                <div>
                                  Gruppi invitante:
                                  {{
                                    element.gruppo_invitante
                                      ? element.gruppo_invitante
                                      : "Cerimoniale"
                                  }}
                                </div>
                                <div>
                                  Gruppi invitato:
                                  {{
                                    element.gruppo_invitato
                                      ? element.gruppo_invitato
                                      : "Cerimoniale"
                                  }}
                                </div>
                                <hr />
                                <div>Sessioni:</div>
                                <div
                                  v-for="sessione in element.sessioni"
                                  :key="sessione.id"
                                >
                                  {{ sessione.session_name }}
                                </div>
                                <hr />
                                <div>Note:</div>
                                <div>{{ element.notes }}</div>
                                <hr />
                                <div>Note interne:</div>
                                <div>{{ element.internal_notes }}</div>
                              </template>
                            </Popper>

                            <i
                              class="bi bi-printer fs-4"
                              :style="
                                element.to_print ? 'color: red' : 'color: green'
                              "
                              @click="stampaPersona(element, true)"
                            ></i>
                            <input
                              class="ms-2 my-1"
                              name="checkbox_print"
                              type="checkbox"
                              :checked="element.checked"
                              @click="element.checked = $event.target.checked"
                            />
                          </div>
                        </div>
                        <template
                          v-for="guest in element.guest"
                          :key="guest.id_person"
                        >
                          <!-- v-if="isMaggiorenne(guest.date_of_birth)" -->
                          <div
                            class="lista-item mt-3 ms-5 d-flex justify-content-between"
                            :class="
                              guest.isDragging || guest.id_seat != -1
                                ? 'bg-secondary'
                                : 'bg-accompagnatori'
                            "
                            draggable="true"
                            :style="
                              index <= guest.numero_giocatori_fissi - 1
                                ? 'cursor: not-allowed'
                                : 'cursor: grab'
                            "
                            v-on:dragstart="
                              guest.isDragging = true;
                              handleDragStart($event, guest);
                            "
                            v-on:dragend="guest.isDragging = false"
                          >
                            <div class="font-italic text-uppercase">
                              {{ guest.surname }} {{ guest.name }}
                              {{
                                moment(guest.date_of_birth).format("DD/MM/YYYY")
                              }}
                              ({{ guest.ean14_code }})
                            </div>
                            <div class="btn btn-sm dropdown p-1">
                              <Popper arrow v-if="guest.accreditation_number">
                                <button type="button">
                                  <i class="bi bi-qr-code text-dark fs-4"></i>
                                </button>
                                <template #content>
                                  <div>
                                    {{ guest.accreditation_number }}
                                  </div></template
                                >
                              </Popper>
                              <i
                                class="bi bi-pen text-dark fs-4"
                                @click="
                                  selected_id_person_guest = guest.id_person
                                "
                              ></i>
                              <button
                                class="btn btn-sm dropdown p-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                @click="deletePerson(guest)"
                              >
                                <i class="bi bi-trash text-dark fs-4"></i>
                              </button>
                              <Popper
                                arrow
                                class="col-3"
                                v-if="!isMaggiorenne(guest.date_of_birth)"
                              >
                                <button type="button">
                                  <i
                                    class="bi bi-exclamation-triangle-fill text-danger text-center"
                                  ></i>
                                </button>
                                <template #content>
                                  <div>Minorenne</div>
                                </template>
                              </Popper>
                              <Popper arrow class="m-0" style="border: 0">
                                <button type="button">
                                  <i
                                    class="bi bi-info-circle-fill text-center"
                                  ></i>
                                </button>
                                <template #content>
                                  <div>
                                    Gruppi invitante:
                                    {{
                                      guest.gruppo_invitante
                                        ? guest.gruppo_invitante
                                        : "Cerimoniale"
                                    }}
                                  </div>
                                  <div>
                                    Gruppi invitato:
                                    {{
                                      guest.gruppo_invitato
                                        ? guest.gruppo_invitato
                                        : "Cerimoniale"
                                    }}
                                  </div>
                                  <hr />
                                  <div>Sessioni:</div>
                                  <div
                                    v-for="sessione in guest.sessioni"
                                    :key="sessione.id"
                                  >
                                    {{ sessione.session_name }}
                                  </div>
                                  <hr />
                                  <div>Note:</div>
                                  <div>{{ element.notes }}</div>
                                </template>
                              </Popper>

                              <i
                                class="bi bi-printer fs-4"
                                @click="stampaPersona(guest, true)"
                                :style="
                                  !guest.to_print
                                    ? 'color: green'
                                    : 'color: red'
                                "
                              ></i>

                              <input
                                class="ms-2 my-1"
                                name="checkbox_print"
                                type="checkbox"
                                @click="guest.checked = $event.target.checked"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <div
                        v-show="!filtered_seats.length"
                        class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
                      >
                        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
                        <span class="text-gray-700 ps-3 fs-2">
                          {{
                            customNoElementMessage
                              ? customNoElementMessage
                              : "Attenzione! Nessun risultato."
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body" v-else>
              <div class="card-body p-6" v-if="loaded_person && person.name">
                <div class="ps-5 pe-5 repeater-form">
                  <div class="row pb-4">
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3">Cognome</label>
                      <Field name="surname" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="surname" />
                    </div>
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3">Nome</label>
                      <Field name="name" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="name" />
                    </div>

                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Data nascita</label
                      >

                      <VueDatePicker
                        placeholder="Data di nascita"
                        :max-date="new Date()"
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        v-model="date_of_birth_var"
                        @update:modelValue="
                          date_of_birth_var = $event
                            ? moment($event).format('YYYY-MM-DD')
                            : null
                        "
                      />
                      <Field
                        name="date_of_birth"
                        type="hidden"
                        v-model="date_of_birth_var"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="date_of_birth" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Numero di telefono</label
                      >
                      <Field name="phone_number" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="phone_number" />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-700 pe-3">Email </label>
                      <Field name="email" class="form-control" type="email" />
                      <ErrorMessage v-show="view_errors" name="email" />
                    </div>
                  </div>
                </div>
              </div>
              <Loader v-else />
            </div>
            <div class="modal-footer">
              <button
                v-if="selected_id_person_guest"
                type="button"
                class="me-3 badge bg-light-warning rounded text-warning fs-6"
                @click="selected_id_person_guest = null"
              >
                Ritorna alla lista
              </button>
              <button
                v-if="!selected_id_person_guest && filtered_seats.length"
                type="button"
                class="badge bg-light-warning text-warning rounded blueFit fs-6"
                @click="stampaBraccialetti(seats)"
              >
                Stampa selezionati
              </button>
              <button
                v-if="!selected_id_person_guest && filtered_seats.length"
                type="button"
                class="badge bg-light-success text-success rounded blueFit fs-6"
                @click="stampaBraccialetti(null)"
              >
                Stampa tutti
              </button>
              <button
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-6"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="kt_modal_print_person_from_session_close_button"
                @click="freetext = ''"
              >
                Chiudi
              </button>
              <button
                v-if="selected_id_person_guest"
                type="submit"
                class="badge bg-light-success text-success rounded blueFit fs-6"
              >
                Salva
              </button>
            </div>
          </Form>

          <ModalEditGuest
            :id_person="selected_id_person_guest"
            @getList="console.log('lista')"
          />
        </template>
        <template v-else-if="loaded_session && id_session && !seats">
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Si è verificato un errore. Riprovare più tardi.
          </span>
        </template>
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from "moment";
import { format, formatDateITA, dateToReset } from "@/functions/formatDate";

import {
  callFunctionAfterAlert,
  alertFailed,
  alertSuccess,
} from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import ModalEditGuest from "@/components/invitations/ModalEditGuest.vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import Popper from "vue3-popper";
import InputList from "@/components/utility/InputList.vue";
import { getBraccialetto } from "@/functions/getBraccialetto";
import { isMaggiorenne } from "@/functions/formatDate.js";

export default {
  name: "add-session-modal",
  components: {
    ModalEditGuest,
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    Popper,
    InputList,
  },
  props: {
    id_session: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
    selected_session_name: {
      type: String,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    // const { element: lookups, getElement: get_lookups } = useApiCall(
    //   API.LOOKUP_GET,
    //   { keys: "ll|gg" }
    // );
    // get_lookups();

    const payload_session = ref();

    const {
      element: session_results,
      getElement: get_session,
      loaded: loaded_session,
      status: status_session,
    } = useApiCall(API.SEATS_SESSION_PRINT_VIEW, payload_session);

    const payload_click = ref();
    const {
      element: res_click,
      getElement: click_print,
      loaded: loaded_print,
      status: status_print,
    } = useApiCall(API.SEATS_SESSION_PRINT_CLICK, payload_click);
    const session_result_copy = ref(null);
    watch(
      () => props.id_session,
      async () => {
        session_result_copy.value = null;
        payload_session.value = {
          id_session: props.id_session,
          id_event: props.id_event,
          id_year: props.id_year,
        };
        await get_session();
        if (session_results.value && session_results.value.results[0].seats) {
          session_result_copy.value = session_results.value;
        }
      }
    );

    const tableHeader = ref([
      {
        name: "Nome",
        key: "name",
      },
      {
        name: "Cognome",
        key: "surname",
      },
      {
        name: "data di nascita",
        key: "date_of_birth",
      },
      {
        name: "Numero di telefono",
        key: "phone_number",
      },
      {
        name: "N° seduta",
        key: "id_seat",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const session = computed(() => {
      return props.id_session &&
        session_result_copy.value &&
        session_result_copy.value.results
        ? session_result_copy.value.results[0]
        : {};
    });
    const seats = computed(() => {
      return props.id_session &&
        session_result_copy.value &&
        session_result_copy.value.results
        ? session_result_copy.value.results[0].seats
        : [];
    });
    const BrowserPrint = window.BrowserPrint;
    const device = ref(null);
    const isPrinting = ref(false);
    BrowserPrint.getLocalDevices(function (deviceList) {
      device.value = deviceList.printer[0];
    });
    const stampaPersona = async (data, isSingola = false) => {
      payload_click.value = {
        id_person: data.id_person,
        id_event: props.id_event,
        id_year: props.id_year,
      };
      click_print();

      const cmds = getBraccialetto(data.surname, data.name, data.ean14_code);
      // if (device.value) {
      //   isPrinting.value = true;
      //   await device.value.send(
      //     cmds,
      //     function (success) {

      //       isPrinting.value = false;
      //     },
      //     function (error) {
      //       console.error("Error:" + error);
      //       isPrinting.value = false;
      //     }
      //   );
      // }

      // session_result_copy.value.results[0].seats.forEach((seat, index) => {
      //   seat.to_print = session_results.value.results[0].seats[index].to_print;
      //   if (seat.guest) {
      //     seat.guest.forEach((guest, y) => {
      //       guest.to_print =
      //         session_results.value.results[0].seats[index].guest[y].to_print;
      //     });
      //   }
      // });
      if (isSingola) {
        if (device.value) {
          isPrinting.value = true;
          await device.value.send(
            cmds,
            function (success) {
              console.log("Sent to printer");
              isPrinting.value = false;
            },
            function (error) {
              console.error("Error:" + error);
              isPrinting.value = false;
            }
          );
        }
        await get_session();
        session_result_copy.value.results[0].seats.forEach((seat, index) => {
          seat.to_print =
            session_results.value.results[0].seats[index].to_print;
          if (seat.guest) {
            seat.guest.forEach((guest, y) => {
              guest.name =
                session_results.value.results[0].seats[index].guest[y].name;
              guest.surname =
                session_results.value.results[0].seats[index].guest[y].surname;
              guest.to_print =
                session_results.value.results[0].seats[index].guest[y].to_print;
            });
          }
        });
      }
      return {
        surname: data.surname,
        name: data.name,
        ean14_code: data.ean14_code,
      };
    };

    const selected_id_person_guest = ref(null);

    const printingOrder = ref([]);
    const numberOfpersonToPrint = ref([]);
    const orderOfPrinterWork = ref("");

    const stampaBraccialetti = async (seats_arr) => {
      if (seats_arr) {
        seats_arr.forEach(async (element) => {
          if (element.checked) {
            stampaPersona({
              name: element.name,
              surname: element.surname,
              ean14_code: element.ean14_code,
              id_person: element.id_person,
            });
            printingOrder.value.push(element);
            numberOfpersonToPrint.value++;
            const cmds = getBraccialetto(
              element.surname,
              element.name,
              element.ean14_code
            );
            orderOfPrinterWork.value = orderOfPrinterWork.value + cmds;
            element.checked = false;
            if (element.guest) {
              element.guest.forEach(async (element) => {
                if (element.checked) {
                  stampaPersona({
                    name: element.name,
                    surname: element.surname,
                    ean14_code: element.ean14_code,
                    id_person: element.id_person,
                  });
                  printingOrder.value.push(element);
                  numberOfpersonToPrint.value++;
                  const cmds = getBraccialetto(
                    element.surname,
                    element.name,
                    element.ean14_code
                  );
                  orderOfPrinterWork.value = orderOfPrinterWork.value + cmds;
                  element.checked = false;
                }
              });
            }
          }
        });
      } else {
        seats.value.forEach(async (element, index) => {
          stampaPersona({
            name: element.name,
            surname: element.surname,
            ean14_code: element.ean14_code,
            id_person: element.id_person,
          });
          printingOrder.value.push(element);
          numberOfpersonToPrint.value++;
          const cmds = getBraccialetto(
            element.surname,
            element.name,
            element.ean14_code
          );
          orderOfPrinterWork.value = orderOfPrinterWork.value + cmds;
          if (element.guest) {
            element.guest.forEach(async (element) => {
              stampaPersona({
                name: element.name,
                surname: element.surname,
                ean14_code: element.ean14_code,
                id_person: element.id_person,
              });
              printingOrder.value.push(element);
              numberOfpersonToPrint.value++;
              const cmds = getBraccialetto(
                element.surname,
                element.name,
                element.ean14_code
              );
              orderOfPrinterWork.value = orderOfPrinterWork.value + cmds;
            });
          }
        });
        // if (printingOrder.value.length == numberOfpersonToPrint.value) {

        //   await get_session();
        //   session_result_copy.value.results[0].seats.forEach((seat, index) => {
        //     seat.to_print =
        //       session_results.value.results[0].seats[index].to_print;
        //     if (seat.guest) {
        //       seat.guest.forEach((guest, y) => {
        //         guest.to_print =
        //           session_results.value.results[0].seats[index].guest[
        //             y
        //           ].to_print;
        //       });
        //     }
        //   });
        //   await device.value.send(
        //     orderOfPrinterWork.value,
        //     function (success) {

        //       isPrinting.value = false;
        //       return;
        //     },
        //     function (error) {
        //       console.error("Error:" + error);
        //       isPrinting.value = false;
        //       return;
        //     }
        //   );
        // }
        // });
      }
      if (printingOrder.value.length == numberOfpersonToPrint.value) {
        await get_session();
        if (session_results.value && session_results.value.results[0].seats) {
          session_result_copy.value = session_results.value;
        }
        await device.value.send(
          orderOfPrinterWork.value,
          function (success) {
            isPrinting.value = false;
            return;
          },
          function (error) {
            console.error("Error:" + error);
            isPrinting.value = false;
            return;
          }
        );
      }
      orderOfPrinterWork.value = "";
    };

    //CODICE MODIFICA GUEST
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = () => {
      view_errors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      values.id_person = selected_id_person_guest.value;
      values.id_event = props.id_event;
      values.id_year = props.id_year;
      if (!values.date_of_birth) {
        values.date_of_birth = dateToReset;
      }
      await callFunctionAfterAlert(
        API.ANAGRAPHICS_PEOPLE_GUEST_EDIT,
        { ...values, id_person: selected_id_person_guest.value },
        `Sei sicuro di voler modificare l'accompagnatore <b>${values.surname} ${values.name}</b>?`,
        `Accompagnatore modificato con successo`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (isAddedorModifiedPerson) => {
        if (!props.id_person) {
          resetForm();
          date_of_birth_var.value = null;
        }

        if (isAddedorModifiedPerson) {
          selected_id_person_guest.value = null;
          await get_session();
          if (session_results.value && session_results.value.results[0].seats) {
            session_result_copy.value = session_results.value;
          }
          session_result_copy.value.results[0].seats.forEach((seat, index) => {
            seat.to_print =
              session_results.value.results[0].seats[index].to_print;
            if (seat.guest) {
              seat.guest.forEach((guest, y) => {
                guest.name =
                  session_results.value.results[0].seats[index].guest[y].name;
                guest.surname =
                  session_results.value.results[0].seats[index].guest[
                    y
                  ].surname;
                guest.to_print =
                  session_results.value.results[0].seats[index].guest[
                    y
                  ].to_print;
              });
            }
          });
        }
      });
    };
    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      surname: yup.string().required("Obbligatorio"),
      date_of_birth: yup.string().nullable(),
      tax_code: yup.string().nullable(),
      phone_number: yup.string().nullable(),

      id_language: yup.number().nullable(),
      id_group: yup.number().nullable(),
    });

    const date_of_birth_var = ref(null);

    const payload = ref({ id_person: props.id_person });

    const {
      element: personResults,
      loaded: loaded_person,
      getElement: get_person,
      status: status_person,
    } = useApiCall(API.ANAGRAPHICS_PEOPLE_VIEW, payload);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ll|gg" }
    );

    get_lookups();

    watch(selected_id_person_guest, async (newVal) => {
      if (newVal) {
        payload.value.id_person = newVal;
        await get_person();
        date_of_birth_var.value = personResults.value.results[0].date_of_birth;
      } else {
        personResults.value = null;
        date_of_birth_var.value = null;
      }
    });

    const formValues = ref({
      id_language: 1,
      id_group: 1,
    });

    const person = computed(() =>
      selected_id_person_guest.value && personResults.value
        ? personResults.value.results[0]
        : {}
    );

    const freetext = ref("");

    const contieneAccompagnatoreMinorenne = (el) => {
      let arrayMinori = [];
      if (el.guest) {
        el.guest.forEach((guest) => {
          if (!isMaggiorenne(guest.date_of_birth)) {
            arrayMinori.push(guest);
          }
        });
      }

      return arrayMinori;
    };

    const deletePerson = async (data) => {
      await callFunctionAfterAlert(
        API.INVITATION_SESSION_SEATS_DEL,
        {
          id_seat: data.id_seat,
          id_person: data.id_person,
          id_session: props.id_session,
          id_event: props.id_event,
          id_year: props.id_year,
        },
        `Sei sicuro di voler rimuovere la persona <b>${data.surname} ${
          data.name
        }</b> alla sessione <b>${
          session.value.name
        }</b> del giorno <b>${formatDateITA(session.value.date)}</b>?`,
        "Persona rimossa con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (data) => {
        if (data) {
          await get_session();
          if (session_results.value && session_results.value.results[0].seats) {
            session_result_copy.value = session_results.value;
          }
        }
      });
    };

    return {
      deletePerson,
      contieneAccompagnatoreMinorenne,
      freetext,
      selected_id_person_guest,
      stampaPersona,
      tableHeader,
      moment,
      format,
      API,
      session_results,
      loaded_session,
      status_session,
      session,
      formatDateITA,
      seats,

      formValues,
      lookups,
      personResults,
      loaded_person,
      status_person,
      date_of_birth_var,
      schema,
      onSubmit,
      onInvalidSubmit,
      stampaBraccialetti,
      disableButton,
      view_errors,
      person,
      isMaggiorenne,
    };
  },
  computed: {
    filtered_seats() {
      const filtered_people = [];

      this.seats.forEach((persona, index) => {
        persona.idx = index;
        // Controlla se il nome e il cognome dell'oggetto includono la stringa
        if (
          this.freetext &&
          (persona.surname + " " + persona.name)
            .toLowerCase()
            .includes(this.freetext.toLowerCase())
        ) {
          // Se l'oggetto principale soddisfa il filtro, aggiungilo agli oggetti trovati
          filtered_people.push(persona);
        } else if (persona.guest) {
          // Se l'oggetto principale non soddisfa il filtro, controlla gli ospiti
          let ospitiTrovati = persona.guest.filter((ospite) => {
            return (
              this.freetext &&
              (ospite.surname + " " + ospite.name)
                .toLowerCase()
                .includes(this.freetext.toLowerCase())
            );
          });
          // Se ci sono ospiti che soddisfano il filtro, aggiungili agli oggetti trovati insieme all'oggetto principale
          if (ospitiTrovati.length > 0) {
            filtered_people.push(persona);
          }
        }
      });

      return filtered_people.length || this.freetext
        ? filtered_people
        : this.seats;
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
  /* min-height: 600px; */
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.lista-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: 1px solid #c3c4c5;
}
.bg-accompagnatori {
  background-color: rgb(0 65 255 / 38%) !important;
}
</style>
