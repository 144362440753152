<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_person_to_session">
    <div class="modal-dialog">
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="id_person ? person : formValues"
        :validation-schema="schema"
        autocomplete="off"
        v-slot="{ values }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="selected_row && selected_seat">
              Aggiungi persona alla sessione {{ selected_session_name }} Fila
              {{ selected_row }} Posto {{ selected_seat.number }} Settore
              {{ selected_sector }}
            </h5>
            <h5 class="modal-title" v-else>Aggiungi persona a sessioni</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_person_to_session_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card mb-7">
              <div class="card-body pt-6 pb-0">
                <h1 class="w-100 mb-0 badge text-uppercase badge-light-danger">
                  <p class="w-100 text-wrap pt-2">
                    E' possibile aggiungere persone direttamente in sessione
                    solo se non ci sono altri inviti associati alla sua email
                  </p>
                </h1>
                <div class="d-flex overflow-auto h-55px">
                  <Field
                    name="selected_section"
                    type="hidden"
                    class="form-control"
                  />
                  <ul
                    class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                  >
                    <li class="nav-item">
                      <span
                        style="cursor: pointer"
                        aria-current="page"
                        class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                        :class="
                          selected_tab == 'creazione_persona' ? 'active' : ''
                        "
                        @click="
                          selected_tab = 'creazione_persona';
                          values.selected_section = 'creazione_persona';
                          resetAggiuntaDaAnagrafica(values);
                        "
                      >
                        Creazione persona
                      </span>
                    </li>
                    <li class="nav-item">
                      <span
                        style="cursor: pointer"
                        aria-current="page"
                        class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                        :class="
                          selected_tab == 'aggiunta_anagrafica' ? 'active' : ''
                        "
                        @click="
                          selected_tab = 'aggiunta_anagrafica';
                          resetCreazionePersona(values);
                          values.selected_section = 'aggiunta_anagrafica';
                          values.payment_invitation = false;
                          values.payment_guest = false;
                          date_of_birth_var = null;
                          guests = [];
                        "
                      >
                        Aggiunta da anagrafica
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body p-6">
              <template v-if="selected_tab == 'creazione_persona'">
                <div class="ps-5 pe-5 repeater-form">
                  <div class="mb-2 d-flex flex-column">
                    <h1 class="fw-bolder fs-3">DATI PERSONA</h1>
                  </div>
                  <div class="row pb-4">
                    <div class="ps-0 col-3 text-center">
                      <div class="my-1 cursor-pointer" @click="clickImportFile">
                        <input
                          type="file"
                          class="d-none"
                          name="avatar"
                          accept=".jpg, .jpeg, .png"
                          id="addAvatarInvitatoCons"
                          @change="onInputChange"
                        />

                        <img
                          v-if="file && file.file && file.url"
                          :src="file.url"
                          class="rounded-circle width-100 bg-black"
                          alt="..."
                          :style="
                            invito
                              ? invito.photo.includes('default')
                                ? ''
                                : 'border: 2px solid #cbcbcb'
                              : 'border: 2px solid #cbcbcb'
                          "
                          style="width: 4rem; height: 4rem"
                        />

                        <BlankProfile v-else />
                        <!-- <ProfilePictureX /> -->
                      </div>
                    </div>
                    <div class="col-9">
                      <label class="fw-bold text-gray-700 pe-3">Cognome</label>
                      <Field name="surname" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="surname" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-4">
                      <label class="fw-bold text-gray-700 pe-3">Nome</label>
                      <Field name="name" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="name" />
                    </div>

                    <div class="col-4">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Data nascita</label
                      >

                      <VueDatePicker
                        placeholder="Data di nascita"
                        :max-date="new Date()"
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        v-model="date_of_birth_var"
                        @update:modelValue="
                          date_of_birth_var = $event
                            ? moment($event).format('YYYY-MM-DD')
                            : null
                        "
                      />
                      <Field
                        name="date_of_birth"
                        type="hidden"
                        v-model="date_of_birth_var"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="date_of_birth" />
                    </div>
                    <div class="col-4">
                      <label class="fw-bold text-gray-700 pe-3">Gruppo </label>
                      <Field class="form-select" name="id_group" as="select">
                        <option
                          v-for="group in lookupGroup"
                          :key="group.id"
                          :value="group.id"
                        >
                          {{ group.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_group" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Numero di telefono</label
                      >
                      <Field name="phone_number" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="phone_number" />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700 pe-3">Email </label>
                      <Field name="email" class="form-control" type="email" />
                      <ErrorMessage v-show="view_errors" name="email" />
                    </div>
                    <!-- <div class="col-md-3">
                        <label class="fw-bold text-gray-700 pe-3"
                          >Codice fiscale
                        </label>
                        <Field name="tax_code" class="form-control" />
                        <ErrorMessage v-show="view_errors" name="tax_code" />
                      </div> -->
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Lingua parlata
                      </label>
                      <Field class="form-select" name="id_language" as="select">
                        <option
                          v-for="language in lookupLanguage"
                          :key="language.id"
                          :value="language.id"
                        >
                          {{ language.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_language" />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Ospite a pagamento
                      </label>
                      <br />
                      <Field
                        name="payment_invitation"
                        type="checkbox"
                        id="payment_invitation"
                        :value="true"
                      />
                      <Field
                        name="max_sessions"
                        type="hidden"
                        id="max_sessions"
                        v-model="listLength"
                      />

                      <ErrorMessage
                        v-show="view_errors"
                        name="payment_invitation"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="ps-5 pe-5 repeater-form">
                  <div class="row pb-4">
                    <div class="col-md-9 filter">
                      <label class="fw-bold text-gray-700 pe-3">Persone</label>
                      <InputList
                        v-if="!id_invitation"
                        :apiLink="API.LOOKUP_PERSON_SEARCH"
                        payloadParameterTextName="surname"
                        payloadParameterTextNameSec="name"
                        payloadParameterTextNameTer="date_of_birth"
                        parameterToShow="nome"
                        @isSelectedElement="
                          values.id_person = $event.id;
                          values.item_id = $event.item_id;
                          values.name = $event.name;
                          values.surname = $event.surname;
                        "
                        :placeholder="`Cercare una persona da aggiungere alla sessione ${selected_session_name}`"
                        getEntireRecord
                        :emitEventOnCancel="false"
                        class="mb-4"
                        id_bottone_chiudi="chiudiPersonaAddPersonaSessione"
                      />

                      <!-- <input
                        v-else
                        class="form-control"
                        :value="
                          invitation.name +
                          ', ' +
                          invitation.surname +
                          ' - ' +
                          formatDateITA(invitation.date_of_birth)
                        "
                      /> -->

                      <Field
                        type="hidden"
                        name="id_person"
                        class="form-control"
                      />
                      <Field
                        type="hidden"
                        name="item_id"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="people" />
                    </div>
                    <div class="col-md-3" v-if="!id_session">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Ospite a pagamento
                      </label>
                      <br />
                      <Field
                        name="payment_invitation"
                        type="checkbox"
                        id="payment_invitation"
                        :value="true"
                      />
                      <Field
                        name="max_sessions"
                        type="hidden"
                        id="max_sessions"
                        v-model="listLength"
                      />

                      <ErrorMessage
                        v-show="view_errors"
                        name="payment_invitation"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <div class="mt-2 ps-5 pe-5 repeater-form">
                <AccordionAccompagnatori
                  v-if="!id_session"
                  :accompagnatori="guests"
                  @GetAcc="get_all_data"
                  @setpayment_guest="values.payment_guest = $event"
                  @accompagnatoreInserito="guests = $event"
                  :payment_guest="values.payment_guest"
                  :from_add_person_to_session="true"
                />
              </div>

              <div class="mt-2 ps-5 pe-5 repeater-form" v-if="!id_session">
                <div class="row">
                  <div
                    class="align-items-center mb-2 d-flex justify-content-between"
                  >
                    <h1 class="align-items-center fw-bolder my-2 fs-3">
                      ASSEGNA SESSIONI
                    </h1>
                  </div>

                  <div class="row">
                    <!-- @checkedElement="set_sessions($event)" -->

                    <SessionsList_copy
                      :key="rerender"
                      :from_invitations="false"
                      :from_invitations_consigliere="true"
                      :id_year_from_invitation="id_year"
                      :id_event_from_invitation="id_event"
                      :checked_list_from_invitation="selected_sessions"
                      :disable_semifinals_finals="values.id_type == 2"
                      :is_ospite="values.id_type == 4 || values.id_type == 3"
                      :selected_sessions_length="selected_sessions.length"
                      :max_sessions_dirigente_ospite="values.max_sessions"
                      @checkedElementSelezionato="
                        set_sessions($event, 'active')
                      "
                      @checkedElementAccompagnatore="
                        set_sessions($event, 'guest')
                      "
                      @listLength="listLength = $event"
                      :accompagnatori="guests"
                    />
                    <Field
                      type="hidden"
                      name="sessions_length"
                      v-model="listLength"
                    />
                    <Field
                      type="hidden"
                      name="sessions"
                      v-model="selected_sessions"
                    />
                    <ErrorMessage v-show="view_errors" name="sessions" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_person_to_session_close_button"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-light-success text-success rounded blueFit fs-6"
            >
              Salva
            </button>
          </div>

          <!-- <Loader v-else /> -->
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from "moment";
import { format } from "@/functions/formatDate";

import {
  callFunctionAfterAlert,
  alertFailed,
  alertSuccess,
  alertComposto,
} from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import { requestAppPost } from "@/db/dbManag";

import InputList from "@/components/utility/InputList.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import SessionsList_copy from "@/views/SessionsList_copy.vue";
import AccordionAccompagnatori from "@/components/invitations/AccordionAccompagnatori.vue";
import BlankProfile from "@/components/invitations/SVG/BlankProfile.vue";
import useFileList from "@/composables/file-list";
import { fileSender } from "@/db/dbManag";
export default {
  name: "add-session-modal",
  components: {
    AccordionAccompagnatori,
    VueDatePicker,
    Field,
    ErrorMessage,
    Form,
    InputList,
    SessionsList_copy,
    BlankProfile,
  },
  props: {
    id_session: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
    selected_session_name: {
      type: String,
    },
    selected_seat: {
      type: Object,
    },
    selected_row: {
      type: Number,
    },
    selected_sector: {
      type: String,
    },
    sessioni_all: {
      type: Array,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      //   values.people = JSON.stringify(selected_people.value);
      if (props.id_session) {
        // values.sessions = [{ id_session: props.id_session, selected: true }];
        values.sessions = [];
        values.max_sessions = 1;
        props.sessioni_all.forEach((sessione) => {
          selected_sessions.value.push({
            id_session: sessione.id,
            selected: props.id_session == sessione.id ? true : false,
          });
          values.sessions.push({
            id_session: sessione.id,
            selected: props.id_session == sessione.id ? true : false,
          });
        });
        selected_sessions_str.value = JSON.stringify(values.sessions);
      } else {
        values.sessions.forEach((element) => {
          if (values.id_type == 2 || values.id_type == 3) {
            element.selected = true;
          } else {
            element.false = true;
          }
        });
      }

      values.sessions_array = values.sessions;
      values.sessions = JSON.stringify(values.sessions);
      //   category: 1 dirigente 2 ospite
      if (values.id_type != 3 && values.self_payment) {
        values.self_payment = false;
      }
      if (values.id_type == 4 && !values.email) {
        values.email = `ospite_${values.name}_${values.surname}_${values.date_of_birth}@fake.it`;
      }

      const add_person_new = async () => {
        const guests_copy = JSON.parse(JSON.stringify(guests.value));
        guests_copy.forEach((guest) => {
          delete guest.photo;
          delete guest.fileToSend;
        });
        return requestAppPost(API.INVITATION_ADD_CONFIRM, {
          id_seat: props.selected_seat ? props.selected_seat.id_seat : 0,
          id_person: values.id_person,
          name: values.id_person ? undefined : values.name,
          surname: values.id_person ? undefined : values.surname,
          date_of_birth: values.id_person ? undefined : values.date_of_birth,
          phone_number: values.id_person ? undefined : values.phone_number,
          email: values.id_person ? undefined : values.email,
          id_language: values.id_person ? undefined : values.id_language,
          id_group: values.id_person ? undefined : values.id_group,
          guest: JSON.stringify(guests_copy),
          id_event: props.id_event,
          id_year: props.id_year,
          payment_invitation: values.payment_invitation
            ? values.payment_invitation
            : false,
          payment_guest: !guests.value.length
            ? null
            : values.payment_guest
            ? values.payment_guest
            : false,
          sessions: selected_sessions_str.value,
          id_cf_person: values.id_cf_person,
          binding_invitation: null,
        }).then(({ data: dataSeatAdd, status: reqStatus, statusText }) => {
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 &&
              dataSeatAdd.results &&
              dataSeatAdd.results[0].error)
          ) {
            dataSeatAdd.results &&
            dataSeatAdd.results[0] &&
            dataSeatAdd.results[0].error
              ? alertComposto(dataSeatAdd.results)
              : alertFailed(
                  dataSeatAdd.message
                    ? dataSeatAdd.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
            return false;
          } else {
            return {
              data: dataSeatAdd,
              reqStatus,
              statusText,
            };
          }
        });
      };
      return Swal.fire({
        html: `Sei sicuro di voler invitare ${
          values.id_type == 2
            ? `il dirigente <b>${values.surname} ${values.name}</b>`
            : `l'ospite <b>${values.surname} ${values.name}</b>`
        }?`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: async () => {
          const returnObj = await add_person_new();
          if (file.value.file) {
            await caricaFoto(
              returnObj.data.results[0].item_id_person,
              returnObj.data.results[0].id_invitation,
              null
            );
          }
          if (
            returnObj.data.results[0].item_id_guest &&
            returnObj.data.results[0].item_id_guest.length
          ) {
            returnObj.data.results[0].item_id_guest.forEach(
              async (guest_res) => {
                guests.value.forEach(async (guest_payload) => {
                  if (
                    guest_payload.id == guest_res.id &&
                    guest_payload.fileToSend
                  ) {
                    await caricaFoto(
                      guest_res.item_id,
                      returnObj.data.results[0].id_invitation,
                      guest_payload.fileToSend
                    );
                  }
                });
              }
            );
          }
          if (file.value.file) {
            file.value = ref({});
          }
          return returnObj;
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 &&
              data.results &&
              data.results[0] &&
              data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess(
              props.id_session
                ? "Posto assegnato con successo"
                : "Persona invitata con successo"
            );

            resetForm();
            date_of_birth_var.value = null;
            document
              .getElementById("kt_modal_add_person_to_session_close_button")
              .click();
            emit("getList");
            guests.value = [];
            return data;
          }
        } else {
          return false;
        }
      });
    };
    const schema = yup.object().shape({
      // sezione
      selected_section: yup.string(),
      //sezione anagrafica
      id_person: yup.number().when(["selected_section"], {
        is: (selected_section) => {
          return selected_section == "aggiunta_anagrafica";
        },
        then: (schema) => schema.required("Obbligatorio"),
      }),
      item_id: yup.string().when(["selected_section"], {
        is: (selected_section) => {
          return selected_section == "aggiunta_anagrafica";
        },
        then: (schema) => schema.required("Obbligatorio"),
      }),
      // sezione crea persona
      name: yup.string().when(["selected_section"], {
        is: (selected_section) => {
          return selected_section == "creazione_persona";
        },
        then: (schema) => schema.required("Obbligatorio"),
      }),
      surname: yup.string().when(["selected_section"], {
        is: (selected_section) => {
          return selected_section == "creazione_persona";
        },
        then: (schema) => schema.required("Obbligatorio"),
      }),
      date_of_birth: yup
        .string()
        .nullable()
        .when(["selected_section"], {
          is: (selected_section) => {
            return selected_section == "creazione_persona";
          },
          then: (schema) => schema.required("Obbligatorio"),
        }),
      phone_number: yup.string(),
      email: yup.string().email("Inserire una email valida"),
      id_language: yup.number(),
      id_group: yup.number(),
    });

    const date_of_birth_var = ref(null);
    const date_of_birth_var_acc = ref(null);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ll|gg" }
    );
    get_lookups();

    const selected_tab = ref("creazione_persona");

    const formValues = ref({
      id_language: 1,
      id_group: 1,
      selected_section: "creazione_persona",
    });

    const rerender = ref(0);

    const selected_sessions = ref([]);
    const selected_sessions_str = ref([]);

    const set_sessions = (values, checkboxClicked) => {
      selected_sessions.value = [];
      values.forEach((el) => {
        const objtoPush = {};
        // if (el.selezionato) {
        objtoPush.id_session = el.id;
        objtoPush.selected = el.selezionato ? el.selezionato : false;
        const guests_copy = el.guest
          ? JSON.parse(JSON.stringify(el.guest))
          : [];
        const guest_JSON_copy = [];
        guests_copy.forEach((guest) => {
          guest_JSON_copy.push({ id_guest: guest });
        });
        // if (guests_copy.length) {
        //   guests_copy.forEach((element, index) => {
        //     delete guests_copy[index].name;
        //     delete guests_copy[index].surname;
        //     delete guests_copy[index].date_of_birth;
        //     delete guests_copy[index].id;
        //     delete guests_copy[index].selected;
        //     delete guests_copy[index].nominative;
        //   });
        // }

        objtoPush.guest = guest_JSON_copy;
        // }
        if (el.accompagnatore) {
          objtoPush.guest_to_insert = el.accompagnatore;
        }
        if (objtoPush.id_session)
          selected_sessions.value.push({ ...objtoPush });

        // if (el.checked) {

        //   selected_sessions.value.push({ id_session: el.id });

        // }
      });
      selected_sessions_str.value = JSON.stringify(selected_sessions.value);
    };

    const listLength = ref(0);

    const resetAggiuntaDaAnagrafica = (values) => {
      values.id_person = null;
      values.item_id = null;
      values.name_acc = "";
      values.surname_acc = "";
      values.date_of_birth_acc = "";
      values.name = "";
      values.surname = "";
      selected_sessions.value = [];
      selected_sessions_str.value = "";
      rerender.value++;
      values.payment_invitation = false;
      values.payment_guest = false;
      date_of_birth_var.value = null;
      guests.value = [];
    };
    const resetCreazionePersona = (values) => {
      values.name = "";
      values.surname = "";
      values.date_of_birth = "";
      values.phone_number = "";
      values.email = "";
      values.id_language = null;
      values.name_acc = "";
      values.surname_acc = "";
      values.date_of_birth_acc = "";
      selected_sessions.value = [];
      selected_sessions_str.value = "";
      rerender.value++;
      values.payment_invitation = false;
      values.payment_guest = false;
      date_of_birth_var.value = null;
      guests.value = [];
    };
    const guests = ref([]);

    //file

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const file_controllato = ref(false);
    const errori_txt = ref("");

    const caricaFoto = async (
      item_id,
      id_invitation_crated = null,
      differentFile = null
    ) => {
      if (!file.value.file && differentFile) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      const bodyFormData = ref(new FormData());

      bodyFormData.value.append(
        "file",
        differentFile ? differentFile.file : file.value.file
      );
      bodyFormData.value.append("item_id", item_id);
      bodyFormData.value.append(
        "id_invitation",
        id_invitation_crated ? id_invitation_crated : props.invito.id
      );
      bodyFormData.value.append("id_event", props.id_event);
      // bodyFormData.value.append("id_user", item_id);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("Refresh");
          }
        }
      );
    };

    const clickImportFile = () => {
      document.getElementById(`addAvatarInvitatoCons`).click();
    };

    return {
      onInputChange,
      file,
      clickImportFile,
      guests,
      resetAggiuntaDaAnagrafica,
      resetCreazionePersona,
      set_sessions,
      listLength,
      selected_sessions,
      selected_sessions_str,
      rerender,
      formValues,
      selected_tab,
      lookups,
      moment,
      format,
      date_of_birth_var,
      date_of_birth_var_acc,
      schema,
      onSubmit,
      onInvalidSubmit,
      API,
      view_errors,
    };
  },
  computed: {
    lookupLanguage() {
      return this.lookups &&
        this.lookups.result[0] &&
        this.lookups.result[0].languages
        ? this.lookups.result[0].languages
        : [];
    },
    lookupGroup() {
      return this.lookups &&
        this.lookups.result[1] &&
        this.lookups.result[1].groups
        ? this.lookups.result[1].groups
        : [];
    },
    session() {
      return this.id_session && this.sessionResults
        ? this.sessionResults.results[0]
        : {};
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
